//set the height of the named element in dependency of the window height
function setElementHeight(elementName) {
  let windowHeight = window.innerHeight;
  let screen = document.getElementById(elementName);
  screen.style.height = windowHeight + "px";
}

function screenHeight(elementName) {
  if (elementName !== "undefined") {
    setElementHeight(elementName);
  }
}

export { screenHeight };
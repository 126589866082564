let iosDetection = () => {
  let isIOS = function () {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  };
  if (isIOS()) {
    let sceneEls = document.getElementsByClassName("szene-links");
    if (sceneEls.length !== 0) {
      Array.prototype.forEach.call(sceneEls, (sceneEl) => {
        let href = sceneEl.getAttribute("href");
        sceneEl.setAttribute("href", href.replace(/(.*)\.html/, "$1_ios.html"));
      });
    }
  }
}

export {iosDetection};
import styles from "./index.sass";

import { iosDetection } from "./ios.js";
iosDetection();

import { screenHeight } from "./screen"; //a script to determine the window height and pass it to an element
let elementName = "sections";
screenHeight(elementName);

import { animation } from "./animation"; //all animation stuff witch will come
animation();

import {setScrollStart} from "./sitepos";
setScrollStart();
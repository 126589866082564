function sitePos (szenedata) {
  window.sessionStorage.setItem('szene', szenedata);
}

function setScrollStart () {
  let lastSzene = window.sessionStorage.getItem('szene');
  let sectionsEl = document.getElementById("sections")
  let windowHeight = window.innerHeight;
  //console.log(lastSzene);
  function setPos () {
    let scrollPos = windowHeight * (lastSzene - 1);
    sectionsEl.scrollTop = scrollPos;
    window.sessionStorage.setItem('szene', 0);
  }
  if (lastSzene == 2) {
    setPos();
  }
  else if (lastSzene == 3) {
    setPos();
  }
  else if (lastSzene == 4) {
    setPos();
  }
  else if (lastSzene == 5) {
    setPos();
  }
  else if (lastSzene == 6) {
    setPos();
  }
}

export { sitePos };
export {setScrollStart};